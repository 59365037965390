.SlideshowPage {
  --slideshow-bg-color: #000;
  --slideshow-transition: "ease-in-out";

  overflow-y: hidden;
  text-align: center;
  padding: .10in 0;
  background-color: var(--slideshow-bg-color);
  height: 100vh;

  .LoadingContainer {
    color: white;
    min-height: 100vh;
    display: flex;
    place-content: center center;
    flex-direction: column;
  }

  .SlideshowContainer {
    display: flex;
    justify-content: space-around;
    background-position: center;
    margin-bottom: 10px;
    max-height: calc(100vh - .4in - 6rem)
  }

  .NoImageContainer {
    color: white;
    min-height: 100vh;
    display: flex;
    place-content: center center;
    flex-direction: column;
  }

  :global {

    .image-gallery-slide {
      opacity: 0;
      transition: opacity 500ms var(--slideshow-transition) !important;
      background-color: transparent;
    }

    .image-gallery-content {

      &.fullscreen {

        .image-gallery-fullscreen-button {
          display: none;
        }

        .image-gallery-play-button {
          display: none;
        }
      }

    }

    .imageClass {

      .image-gallery-image {
        text-align: center;
        height: calc(100vh - .6in - 6rem) !important;
        width: calc(100vh - .6in - 6rem) !important;

        img {
          height: calc(100vh - .6in - 6rem);
          width: calc(100vh - .6in - 6rem);
        }
      }
    }

    .image-gallery-thumbnails-container {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: flex-end;
      flex-wrap: wrap;
      height: 100%;
    }

    .image-gallery-slide.center {
      opacity: 1;
    }

    .image-gallery-content
    .image-gallery-slide
    .image-gallery-image {
      text-align: center;

      img {
        height: calc(100vh - .6in - 6rem);
        width: calc(100vh - .6in - 6rem);
      }
    }
  }

  .BriziSlideshow {
    display: flex;
    text-align: left;
    background-color: var(--slideshow-bg-color);

    .thumbClass {
      width: 12vw;
      place-content: center center;
      margin: 10px 0;
    }
  }

  .CTA {
    font-size: 3.74rem;
    color: white;
    background-color: var(--slideshow-bg-color);

    img {
      width: 100%;
    }
  }
}
